<script lang="ts" setup>
import { Vue3Lottie } from 'vue3-lottie'
import MilestoneAnimation from "@/assets/features_milestones.json";
const milestones = ref();
const msController = ref(null);

const restart = () => {
		const controller = msController.value as any
		controller.stop()
		controller.play()  
}
</script>
<template>
    <Intersection @enter="restart" :threshold="0.1">

        <div class="px-4 lg:mx-auto max-w-screen-lg">
            <div ref="milestones" class="flex flex-col md:flex-row-reverse gap-8 bg-[#F2E8FE] mx-auto rounded-2xl items-center py-8 md:py-12 px-8 md:px-10">
							<div class="w-full md:mt-4 bg-primary-500 px-6 pt-4 md:py-10 rounded-xl">

								<ClientOnly> 
									<Vue3Lottie
											ref="msController"
											alt="Smoxy milestones aniamtion"
									
											:loop="false"
											:auto-play="false"
											:animationData="MilestoneAnimation" 
											/>
							 </ClientOnly> 
							</div>
                
                <div class="pt-6 md:pt-0 md:px-0">
                    <p class="text-[#7E1EF8]">{{$t('home.features.milestones.title')}}</p>
                    <h3 class="text-3xl pr-4 font-bold font-owners text-dark-900">{{$t('home.features.milestones.headline')}}</h3>
                    <p class="pt-4">{{$t('home.features.milestones.body')}}</p>
                </div> 
                
                
            </div>
        </div>

    </Intersection>
</template>